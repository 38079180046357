<template>
  <input class="form-control form-control-sm m-auto"
         v-if="trackBy === 'Wagon' && wagonName === null" :value="wagonName"
         :placeholder="trackBy" v-on:keyup.enter="Save" type="text">

  <input class="form-control form-control-sm m-auto" v-if="trackBy === 'Wagon' && wagonName !== null"
         :value="wagonName['name']" type="text" :class="searchedWagonColor"
         :placeholder="trackBy" v-on:keyup.enter="Save">

  <input class="form-control form-control-sm m-auto" v-if="trackBy === 'Rate'" :value="rate" :placeholder="trackBy"
         v-on:keyup.enter="Save" type="number">
  <input class="form-control form-control-sm m-auto" v-if="trackBy === 'Weight'" :value="actual_weight"
         :placeholder="trackBy" v-on:keyup.enter="Save" type="number">

  <div v-if="trackBy === 'Wagon' && wagonName !== null && code !==''" class="y position-relative">
    <span
        class="order-connected-code position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"> {{
        code
      }}
    </span>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios"

export default {
  name: "wagon_rate_weight",
  emits: ['update'],
  props: {
    id: {
      type: Number
    },
    trackBy: {
      type: String,
    },
    wagonName: {
      type: Object,
    },
    rate: {
      type: String,
    },
    actual_weight: {
      type: Number,
    },
    code: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  methods: {
    async Save(event) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      let data = {
        [this.updateContainerType]: event.target.value,
        wagon_order_number: this.$route.params.id
      }

      try {
        await axios.put(`/wagon_order/expanse/update/${this.id}/`, data)
        this.$emit('update')
        await Toast.fire({
          icon: 'success',
          title: 'Updated successfully',
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
    },
  },
  computed: {
    searchedWagonColor() {
      if (this.$route.query.wagon) {
        if (!this.wagonName) return ''
        let query = this.$route.query.wagon.toString().trim().toLowerCase()
        let wagon = this.wagonName['name'].toString().toLowerCase()
        if (wagon.includes(query)) {
          return 'border-success border-3'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    updateContainerType() {
      switch (this.trackBy) {
        case 'Wagon':
          return 'wagon_name'
        case 'Rate':
          return 'agreed_rate_per_tonn'
        case 'Weight':
          return 'actual_weight'
        default:
          return ''
      }
    }
  },
}
</script>

<style scoped>

</style>