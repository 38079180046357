<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "AdditionalCost",
  props: {
    wagon_id: {
      type: Number,
      required: true,
      default: () => 0
    },
    cost: {
      type: Number,
      required: true,
      default: () => 0
    }
  },
  methods: {
    async save(event) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        await axios.put(`/wagon_order/expanse/update/${this.wagon_id}/`, {
          additional_cost: event.target.value
        })
        await Toast.fire({
          icon: 'success',
          title: 'Updated successfully',
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'An error occurred while updating',
        })
      }
    }
  }
})
</script>

<template>
  <input class="form-control form-control-sm m-auto"
         placeholder="Additional cost"
         v-on:keyup.enter="save"
         type="number"
         :value="cost"
  >
</template>

<style scoped>

</style>